<template>
    <div class="product-news">
      <h3 class="title">
        <span>会议回放</span>
          <p :class="['search-box', {'active': showSearchContent == true}]" @mouseover="() => (this.showSearchContent = true)"
          @mouseleave="() => (this.showSearchContent = false)">
            <input type="text" placeholder="请输入关键词" class="search-inp" @keyup.enter="inputSearch" v-model.trim="searchInfo">
            <span class="search" @click="search"></span>
          </p>  
      </h3>
      <ul class="list" v-if="list.length">
        <li v-for="(item,index) in list" :key="index" @click="getLivePath(item)">
            <img :src="item.coverImg || '/system/file/merck/1350049990842855425'" class="img" alt="">
            <div class="bottom">
              <p class="ellipsis t">{{item.title}}</p>
              <p class="userInfo">
                <span class="user" v-if="item.lecturerList.length" >{{ item.avatar.join(',') }}</span>
                <span class="date">{{ item.liveTime }}</span>
              </p>
               <p class="userInfo">
                <span  class="author">角色：{{item.attendType | attendTypeFilter}}</span>
                <span  class="author">会议形式：{{item.memberSigninType | memberTypeFilter}}</span>
               </p>
            </div>
        </li>
      </ul>
      <div class="list empty-box" v-else>
          暂无会议
      </div>
      <div class="more">
        <span class="more-t" @click="getMore">点击加载更多</span>
      </div>
      <!-- 会议链接弹框 -->
    <!-- <MeetModel v-if="onlineDialogVisible" :meetingInfo="meetingInfo" :visible.sync='onlineDialogVisible' @close='onlineDialogVisible = false' /> -->
    </div>
</template>

<script>
import indexApi from '@/api/index'
// import MeetModel from '@/components/site/model'
export default {
  // components: {MeetModel},
  name: "product-news",
  data() {
    return {
      list: [],
      searchInfo: '',
      page: 1,
      pageSize: 2,
      showSearchContent: false,
      overSize:2,//剩余数量
      total:2,
      // onlineDialogVisible:false,
      meetingInfo:{}
    }
  },
  mounted() {
    this.getPlaybackList();
  },
  methods: {
    // 获取会议回放数据
    getPlaybackList() {
      let params = {
        "condition": {
          "keywords": this.searchInfo,//关键词
          "status": -1 //会议回放传-1
        },
        "page": this.page,
        "size": this.pageSize
      };
      this.$cancel();//取消正在发送的请求
      indexApi.getPlaybackList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          // let newData =   data.filter(item=>
          //   item.meetingPattern=='online' || 
          //   item.meetingPattern == 'online_and_offline'&&item.memberSigninType !='offline_participation'
          // )
          // console.log(newData,data,'data=============')
        
          this.list = this.list.concat(data);
          this.page++;
          this.pageNums = res.data.pageNums;
          
          console.log('res.data.total',res.data.total,res.data.pageNums)
          this.list.map(item=>{
            item.avatar = [];
            item.lecturerList.map(every=>{
              item.avatar.push(every.name);
            })
          })
        }
      },(err)=>{
        console.log(err,'err============')
        this.$message.success(err && err.message || err!=undefined&&'获取失败，请联系管理员！')
      })
    },

    getLivePath(item) {
      let params = {
        meetingId: item.id
      };
       if(item.meetingPattern == 'offline' || 
        (item.meetingPattern == 'online_and_offline'&&item.memberSigninType =='offline_participation')
        ){
            this.$message.error('当前线下会议，请您线下参会');
        }else{
          indexApi.getLivePath(params).then((res)=>{
            if(res && res.code === 1000){
                let data = res && res.data;
          clearInterval(this.$options.timer);
          this.$options.timer = null;
                window.open(data.url)
            }
          },(err)=>{
          
          })
        }
      
    },

    getMore() {
		let that = this;
    console.log(that.page , that.pageNums,'======================')
        if(that.page > that.pageNums){
			console.log('没有更多了')
        }else{
			that.getPlaybackList();
        }
		let button_params = {
			button: "点击加载更多",
			buttonContent: "",
			dataId: "",
			type: parseInt(7)
		}
		that.$point.buttonClickFn(button_params);
    },

    search() {
      if(this.searchInfo){
        this.$router.push(
            {
                name: 'search',
                query: {
                    type: 'e',
                    keywords: this.searchInfo
                }
            }
        )
      }
    },

    inputSearch(event) {
      if (event.keyCode == 13){
        this.search();
      }  
    },
  }
}
</script>

<style scoped lang="scss">
.product-news {
  width: 4.5rem;
  background-color: #ffffff;
  border-radius: 0 .2rem .2rem 0;
  padding: .5rem;
  min-height: 6.9rem;
  position: relative;
  .title {
    font-size: .2rem;
    color: #503291;
    margin-bottom: .36rem;
    line-height: 1;
    padding-bottom: .15rem;
    border-bottom: 1px solid #ebebeb;
    .search-box{
      float: right;
      position: relative;
      margin-top: -.05rem;
      &.active{
        input.search-inp{
          width: 1.6rem;
        }
      }
      .search-inp{
        width: 0;
        // height: .28rem;
        font-size: .14rem;
        color: #fff;
        background: #2DBECD;
        padding: .08rem .2rem;
        border-radius: 14px;
        border: none;
        outline: none;
        &::placeholder {
            color: #fff;
        }
      }
    }
    .search{
      width: .28rem;
      height: .28rem;
    //   background: #2DBECD;
      border-radius: 50%;
      display: block;
      float: right;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;

      
      
      &::before{
        content: '';
        width: .14rem;
        height: .14rem;
        background: url('./images/search.png') no-repeat center/100% 100%;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        margin-top: .05rem;
        cursor: pointer;
      }
    }
  }
  .list {
    height: 4.6rem;
    overflow-y: scroll;
    overflow-x: hidden;
    &.empty-box{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li {
      position: relative;
      margin-bottom: .4rem;
      cursor: pointer;
      a{
        display: block;
      }
    }
    .img {
      display: block;
      height: 2.07rem;
      width: 100%;
      border-radius: .08rem .4rem .08rem .08rem;
      overflow: hidden;
    }
    .t {
      color: #fff;
      font-size: .16rem;
      max-width: 3rem;
    }
    .bottom{
      position: absolute;
      box-sizing: border-box;
      padding:0 .15rem .15rem;
      height: 1.37rem;
      left: 0;
      bottom: 0;
      background: linear-gradient(to bottom,rgba(0,0,0,0) , rgba(0,0,0,.4));;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 0 0 .08rem .08rem;
    }
    .userInfo{
      font-size: .14rem;
      font-family: Noto Sans CJK SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: .1rem;
      .author {
      margin-right: .2rem;
    }
      .user{
          display: inline-block;
          margin-right: .2rem;
          &::before{
            content: '';
            width: .14rem;
            height: .14rem;
            background: url('./images/p.png') no-repeat center/ 100% 100%;
            display: inline-block;
            vertical-align: middle;
            margin-top: -.02rem;
            margin-right: .05rem;
          }
      }
      .date{
        display: inline-block;
        &::before{
            content: '';
            width: .14rem;
            height: .14rem;
            background: url('./images/t.png') no-repeat center/ 100% 100%;
            display: inline-block;
            vertical-align: middle;
            margin-top: -.02rem;
            margin-right: .05rem;
          }
        
      }
    }

  }
  .more {
    position: absolute;
    left: 50%;
    bottom: -.05rem;
    margin-left: -1.2rem;
    width: 2.4rem;
    height: .69rem;
    background: url("~./images/more-bg.png") center center / 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .more-t {
      display: block;
      height: .28rem;
      padding-left: .35rem;
      color: #fff;
      font-size: .14rem;
      line-height: .28rem;
      background: url("~./images/more.png") left center /.26rem .24rem no-repeat;
    }
  }
}
</style>
