<template>
  <div class="hall-container">
    <div class="scroll-container">
     <div class="main-container">
      <div class="bg-wrapper">
         
        <div class="content-wrapper">
            <div class="content-container swiper-container">
              <ul class="tab-wrapper swiper-wrapper">
                <li v-for="(item, index) of tabList" class="tab swiper-slide" @click="handleToggleTab(index, item)" :class="{active: curTab === index}" :key="index">
                  <!-- <img :src="item.img" class="img" alt=""> -->
                  <span class="txt">{{item.title}}</span>
                </li>
              </ul>
            </div>

         
          <!-- <video-player class="v" :options="playOptions">播放</video-player> -->
          <div class="video-wrap" @click="goPath">
              <p class="empty-box" v-if="!tabList.length && loading">欢迎来到CME在线</p>
				<img style="display:none;" v-if="detailData.attendType == 'NONE'" class="code-img" :src="detailData.qrcode" />
          </div>
          <p class="video-tag" v-if="tabList.length && loading">{{ detailData && detailData.statusText }}</p>
          <p class="video-tag" v-if="!tabList.length && loading">暂无会议</p>
          <div class="info-wrapper">
            <h3 class="title ellipsis" :title="detailData && detailData.title">{{ detailData && detailData.title}}</h3>
            <div class="info-content-wrap">
              <p class="info-content" v-if="detailData && detailData.lecturerList">
                <span class="author" >
                  <!-- <img src="./images/1.png" class="avatar" alt=""> -->
                  讲者：<i v-for="(item,index,key) in detailData.lecturerList" :key="key">{{item.name}}</i>
                </span>
                <span  class="author">角色：{{detailData.attendType | attendTypeFilter}}</span>
                <span  class="author">会议形式：{{detailData.memberSigninType | memberTypeFilter}}</span>
                <!-- <span class="t" v-for="i of videoInfo.tagList" :key="i.id">{{i.name}}</span> -->
              </p>
            </div>
            <div class="share-wrapper">
              <div class="phone icon" @mouseover="showErCode" @mouseout="hideErCode">
                手机
                <div class="layer" v-show="isShowErCode">
                  <!-- <img src="./images/qr-code.png" id="qr-code" class="qr-code" alt=""> -->
				  <img :src="imgData && imgData.src" class="qr-code" alt="">
                  <!-- <img :src="imgData && imgData.qr_url" class="qr-code" alt=""> -->
                  <div v-show="false" id="qr-code" class="qr-code" ref="qrCodeDiv"></div>
                  <span class="wt">微信扫一扫， 随身观看</span>
                </div>
              </div>
              <!-- <div class="share icon" @mouseover="showShare" @mouseout="hideShare"> 
                分享
                <div class="layer" v-show="isShowShare">
                  <img :src="imgData && imgData.src" class="qr-code" alt="">
                  <div v-show="false" id="qr-code1" class="qr-code1" ref="qrCodeDiv"></div>
                  <span class="wt">微信扫一扫， 随身观看</span>
                </div>  
              </div> -->
            </div>
          </div>
        </div>
        <div class="news-wrapper">
          <product-news></product-news>
        </div>
      </div>
     </div>


    </div>
         <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        :append-to-body="true"
        top="0"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        @close="centerDialogVisible = false"
        center>
        <div class="code-box" >
            <p>您未报名此会议<br/>微信扫一扫报名参加</p>
            <img class="code-img" :src="detailData.qrcode" />
        </div>
    </el-dialog>
    <!-- 会议链接弹框 -->
    <!-- <MeetModel v-if="onlineDialogVisible" :meetingInfo="meetingInfo" :visible.sync='onlineDialogVisible' @close='onlineDialogVisible = false' /> -->
  </div>
  
</template>

<script>
import Swiper from 'swiper';
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import ProductNews from "./product-news";
import indexApi from '@/api/index'
import config from './config'
import QRCode from 'qrcode'
// import MeetModel from '@/components/site/model'
export default {
  components: {ProductNews, videoPlayer},
  data() {
    return {
      headerImg: './images/1.png',
      curTab: 0,
      tabSwiper:null,
      tabList: [],
      videoInfo: {
        title: '',
        author: '',
        avatar: '',
        qrCode: '',
        tagList: [],
      },
      playOptions: {
        autoplay: false,
        muted: false,
        preload: false,
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: `video/mp4`,
          src: this.src,
        }],
      },
      detailData: {},
      meetingInfo:{},
      statusList: config.meetStatusList,
      isShowErCode: false,
      isShowShare: false,
      imgData: '',
      loading:false,

      centerDialogVisible: false,
      onlineDialogVisible:false,//线下会没有直播间
    }
  },
  created() {
    this.getLiveList();
    
  },
  mounted() {
	  let that = this;
    
    that.$nextTick (()=>{
      this.creatQrCode();
      this.creatSwiper()
      // this.createPicture();
    })
    // this.creatQrCode();
    // this.getData();
  },
  methods: {
    //创建Swiper
		creatSwiper() {
			let _this = this;
			this.tabSwiper = new Swiper('.swiper-container', {
         watchSlidesProgress : false,
        watchSlidesVisibility : true,//如果开启了watchSlidesVisibility，则会在每个可见slide增加一个classname，默认为'swiper-slide-visible'
			 slidesPerView: 'auto',
       spaceBetween: 17,//间距
			 observer:true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents:true, // 修改swiper的父元素时，自动初始化swiper
        
				on: {
					tap: function() {
						console.log('tap====年当前的index=====', this.activeIndex);
						
					}
				}
			});
		},
    handleToggleTab(i,item) {
      this.curTab = i;
      this.detailData = item;
      this.meetingInfo = item.meetingInfo;
    },

    /**
     * 跳转到会议详情
     */
    goPath(){
        if(this.detailData.attendType == 'NONE'){ //如果未报名，提示弹窗
            this.centerDialogVisible = true;
        }else if(this.detailData.meetingPattern == 'offline' || 
        (this.detailData.meetingPattern == 'online_and_offline'&&this.detailData.memberSigninType =='offline_participation')
        ){
            this.$message.error('当前线下会议，请您线下参会');
        }else{
            this.getLivePath();
        }

    },

    // 手机看
    showErCode() {
      this.isShowErCode = true;
    },

    // 关闭手机看
    hideErCode() {
      // this.$refs.qrcode.qrCodeDiv = ''
      this.isShowErCode = false;
    },

    // 分享 isShowShare
    showShare() {
      this.isShowShare = true;
    },

    // 关闭手机看
    hideShare() {
      // this.$refs.qrcode.qrCodeDiv = ''
      this.isShowShare = false;
    },
	
    // 生成二维码
    creatQrCode() {
      let url = window.location.href;
        QRCode.toDataURL(url, (err, base64)=>{
          let image = new Image();
          image.width = 100;
          image.height = 100;
          image.src = base64;
          this.imgData = image;
        })
    },

    createPicture () {
      html2canvas(this.$refs.qrCodeDiv, {
        backgroundColor: null,
        width: 100,
        height: 100
      }).then(canvas => {
        var imgData = canvas.toDataURL('image/jpeg')
        this.imgData = imgData
      })
    },
  
    // 获取会议数据
    getLiveList() {
      let params = {
        // phone: this.form.phone
      };
      indexApi.getLiveList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data;
          this.tabList = data;
          this.detailData = this.tabList.length ? this.tabList[0] : {};
          this.meetingInfo = this.detailData.meetingInfo;
          this.loading = true;
          this.tabList.map((item)=>{
            let statusObj = this.statusList.find((every)=>{
              return every.value === item.status;
            })

            item.statusText = statusObj && statusObj.label || '暂无状态';
          })
        }
      },(err)=>{
        this.$message.error(err && err.message || '获取失败，请联系管理员！')
      })
    },
    // 获取会议路径
    getLivePath() {
      let params = {
        meetingId: this.detailData.id
      };
      indexApi.getLivePath(params).then((res)=>{
		  console.log(res)
        if(res && res.code === 1000){
            let data = res && res.data;
			clearInterval(this.$options.timer);
			this.$options.timer = null;
            window.open(data.url)
        }
      },(err)=>{
       
      })
    },
  }
}
</script>

<style lang="scss">
    .video-js .vjs-big-play-button{
      width: .18rem;
      height: .16rem;
      opacity: 0;
      // background: url('./images/zhib-bg.png') no-repeat center /100% 100%;
    }
</style>

<style scoped lang="scss">
.hall-container {
  background-image: url("~./images/bg.jpg");
  background-size: cover;
  line-height: 1;
}

.scroll-container{
    width: 100%;
    max-width: 100%;
}
.code-box{
    line-height: 0.32rem;
    margin: 0 auto;
    width: 2.5rem;
    height: 3.4rem;
    text-align: center;
    img{
        margin-top:0.3rem;
    }
    
}
.code-img{
    width: 2.5rem;
}

.bg-wrapper {
  display: flex;
  align-items: flex-start;
  background: url("~./images/count-bg.png") top center /100% auto no-repeat;
  margin-top: .6rem;
  position: relative;
  padding: .4rem .5rem;
}

.content-wrapper {
  flex: auto;
  width: 10.1rem;
  margin-right: .1rem;
  background: #fff;
  padding: .5rem;
  border-radius: .2rem 0 0 .2rem;
  .video-wrap {
    width: 100%;
    height: 5.13rem;
    background: url('../../../public/images/h-3.png') no-repeat center /100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // ::v-deep {
    //   .video-js {
    //     width: 100% !important;
    //     height: 100% !important;
    //   }
    // }

    .empty-box{
        color:#fff;
        font-size: 0.24rem;
    }
  }
}
.content-container{
    position: absolute !important;
    left: 1rem !important;
    top: -.22rem !important;
    width: 94%;
    height: 1rem;
}
.tab-wrapper {

  // display: flex;
  // align-items: center;
  padding: 0;
  .tab {
    width: 2.8rem;
    height: .85rem;
    background-color: #ebebeb;
    border-radius: .43rem;
    display: flex;
    align-items: center;
    margin-right: .2rem;
    padding: 0 .25rem;
    cursor: pointer;
    font-size: .16rem;
    line-height: .24rem;
    color: #333;
    position: relative;
    z-index: 10;
    &.active {
      background: url("~./images/active-tab.png") center center /100% 100%;
      color: #fff;
      &::after {
        display: block;
        content: '';
        width: .18rem;
        height: .18rem;
        background-color: #eb3c96;
        position: absolute;
        bottom: -.06rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
    .img {
      width: .95rem;
      height: .55rem;
      margin-right: .1rem;
    }
  }
}

.info-wrapper {
  margin-top: .2rem;
  position: relative;
  font-size: .16rem;
  color: #333;
  .title {
    line-height: 1;
    font-size: .24rem;
    color: #1a1a1a;
    max-width: 6rem;
    margin-bottom: .15rem;
  }
  .info-content-wrap{
    overflow: hidden;
    display: flex;
  }
  .info-content {
    display: flex;
    align-items: center;
    .author {
      color: #4d4d4d;
      display: flex;
      align-items: center;
      margin-right: .2rem;
      .avatar {
        width: .22rem;
        height: .22rem;
        border-radius: 100%;
        margin-right: .08rem;
      }
      i{font-style: normal; display: inline-block; margin-right: .05rem;}
    }
    .t {
      height: .22rem;
      background-color: #f0f0f0;
      border-radius: 2px;
      line-height: .22rem;
      padding: 0 .1rem;
      margin-right: .1rem;
    }
  }
}
.video-tag{
  width: .99rem;
  height: .45rem;
  display: block;
  background: url('./images/zhib-bg.png') no-repeat center /100% 100%;
  position: absolute;
  top: 1.06rem;
  left: 1.15rem; 
  font-size: .14rem;
  font-family: Noto Sans CJK SC;
  font-weight: 400;
  color: #FFFFFF; 
  text-align: center;
  line-height: .4rem;
  cursor: pointer;
}
.share-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  .icon {
    font-size: .14rem;
    color: #999;
    height: .18rem;
    padding-left: .27rem;
    margin-left: .3rem;
    line-height: .18rem;
    background: url("~./images/phone.png") left center / auto .18rem no-repeat;
    position: relative;
    cursor: pointer;
    &.share {
      background-image: url("~./images/share.png");
    }
    // &.phone {
    //   &:hover {
    //     .layer {
    //       display: flex;
    //     }
    //   }
    // }
    .layer {
      position: absolute;
      right: -.22rem;
      top: .3rem;
      // display: none;
      display: flex;
      width: 2.41rem;
      height: 1.24rem;
      background: url("~./images/qr-bg.png") center center / 100% 100%;
      color: #fff;
      font-size: .18rem;
      align-items: center;
      padding: .22rem .06rem .16rem .2rem;
      .qr-code, .qr-code1 {
        width: .9rem;
        height: .9rem;
        margin-right: .14rem;
        margin-top: .1rem;
        img, canvas{
          width: .9rem;
        height: .9rem;
        }
      }
      .wt {
        line-height: .28rem;
      }
    }
  }
}
::v-deep .el-dialog {
    transform: translateY(-50%);
    top: 50%;
}
::v-deep .el-dialog__headerbtn{
    font-size: 18px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close:hover{
    color: #EB3C96;
}
::v-deep .el-dialog__title{
    font-size: 0.24rem;
}
</style>
